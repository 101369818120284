import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import useIsAdminHandler from '../hooks/useIsAdminHandler';

function AdminRouteWrapper({ children, ...other }) {
  const isAdminHandler = useIsAdminHandler();
  return <>{isAdminHandler && <Route {...other}>{children}</Route>}</>;
}

AdminRouteWrapper.propTypes = {
  children: PropTypes.node,
};

export default AdminRouteWrapper;
