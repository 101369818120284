import React, { createContext, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useApiConfigs } from './ApiConfigsContextProvider';
import { HubConnectionBuilder } from '@microsoft/signalr';

const UserHubConectionContext = createContext(null);

export const useUserHubConnection = () => useContext(UserHubConectionContext);

function UserHubConectionProvider({ children }) {
  const { webApiUrl } = useApiConfigs();

  const memoizedConnection = useMemo(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${webApiUrl}/userHub`)
      .withAutomaticReconnect()
      .build();

    connection.start().catch((err) => {
      return console.error(err.toString());
    });

    return connection;
  }, [webApiUrl]);

  useEffect(() => {
    return () => {
      memoizedConnection.stop();
    };
  }, [memoizedConnection]);

  return (
    <UserHubConectionContext.Provider value={memoizedConnection}>{children}</UserHubConectionContext.Provider>
  );
}

UserHubConectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserHubConectionProvider;
